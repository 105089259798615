import React from "react";
import { Grid, Stack, TextField } from "@mui/material";
import useCustomForm from "../../../hooks/useForm";
import CheckBoxInstruction from "../../Tickets/components/CheckBoxInstruction";
import { formatPhoneNumber } from "../../../utils/helpers";

function ViewDetails({ data, onClose }) {
  const { register } = useCustomForm({
    ...data,
    superintendent_number: formatPhoneNumber(data.superintendent_number),
  });

  const fields = [
    {
      name: "scheduled_date",
      label: "Date",
      type: "date",
      disabled: true,
    },
    {
      name: "service_type",
      label: "Service Type",
      type: "text",
      isRequired: true,
      disabled: true,
    },
    {
      name: "building_id",
      label: "Building",
      type: "text",
      isRequired: true,
      disabled: true,
    },
    {
      name: "apartment",
      label: "Apt#",
      type: "text",
      disabled: true,
      isRequired: true,
    },
    {
      name: "unit_size",
      label: "Unit Size",
      type: "text",
      disabled: true,
    },
    {
      name: "unit_status",
      label: "Unit Status",
      type: "text",
      disabled: true,
      isRequired: true,
    },
    {
      name: "borough",
      label: "Borough",
      type: "text",
      disabled: true,
    },

    {
      name: "report_status",
      label: "Ticket Status",
      type: "text",
      disabled: true,
    },
  ];

  const contactPerson = [
    {
      name: "superintendent_name",
      label: "Full Name",
      type: "text",
      disabled: true,
    },
    {
      name: "superintendent_number",
      label: "Phone#",
      type: "text",
      disabled: true,
    },
    {
      name: "additional_comment",
      label: "Additional Comments",
      type: "textarea",
      disabled: true,
    },
  ];

  return (
    <div className="mb-4">
      <Stack>
        <Grid container spacing={2}>
          {fields.map((field, index) => (
            <Grid
              item
              xs={12}
              md={field.type === "textarea" ? 12 : 6}
              key={index}
            >
              <TextField
                label={field.label}
                fullWidth
                {...register(field.name, {
                  required: `${field.label} is required`,
                })}
                InputLabelProps={{ shrink: true }}
                disabled={field.disabled || false}
                placeholder={field.placeholder}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#16304f", // Change this to your desired focus color
                    },
                  },
                  "& .MuiInputLabel-root": {
                    "&.Mui-focused": {
                      color: "#16304f", // Change this to your desired label focus color
                      fontFamily: "segoe_uisemibold",
                      fontSize: "13px",
                    },
                  },
                }}
              />
            </Grid>
          ))}
        </Grid>
        <div className="p-24-0">
          <p className="schedule-appointment-heading">Contact Person</p>
        </div>
        <Grid container spacing={2}>
          {contactPerson.map((field, index) => (
            <Grid
              item
              xs={12}
              md={field.type === "textarea" ? 12 : 6}
              key={index}
            >
              {" "}
              {field.type === "textarea" ? (
                <TextField
                  label={field.label}
                  multiline
                  rows={3}
                  fullWidth
                  {...register(field.name)}
                  disabled={field.disabled || false}
                  placeholder={field.placeholder}
                  InputLabelProps={{ shrink: true }}
                />
              ) : (
                <TextField
                  label={field.label}
                  fullWidth
                  {...register(field.name, {
                    required: `${field.label} is required`,
                  })}
                  InputLabelProps={{ shrink: true }}
                  disabled={field.disabled || false}
                  placeholder={field.placeholder}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#16304f", // Change this to your desired focus color
                      },
                    },
                    "& .MuiInputLabel-root": {
                      "&.Mui-focused": {
                        color: "#16304f", // Change this to your desired label focus color
                        fontFamily: "segoe_uisemibold",
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              )}
            </Grid>
          ))}
          <Grid item xs={12} md={12}>
            <div className="d-flex">
              <div>
                <label class="custom-checkbox">
                  <input
                    type="checkbox"
                    id={`board-list-my-task-checkbox-all-board`}
                    defaultChecked={data.is_prior}
                    disabled={true}
                    //   checked={activeBoardList.length == checkedList.length}
                  />
                  <span class="checkmark"></span>
                </label>
              </div>
              <p className="instruction-text">
                Check this box if the unit is vacant and can be tested PRIOR to
                your selected date if an opening becomes available (This option
                is ONLY available for vacant units)
              </p>
            </div>
          </Grid>
        </Grid>
      </Stack>
    </div>
  );
}

export default ViewDetails;
