import React, { useState } from 'react'
import useCustomMutate from '../../../../../hooks/useMutate';
import { updateBuilding } from '../../../../../sevices/buildingApi';

function HPDalerts({ data }) {
  const [loading, setLoading] = useState(false);
  const { mutate } = useCustomMutate(
    updateBuilding,
    ["building", "units"],
    () => {
      setLoading(false);
    }
  );
  const onChange = (event) => {
    setLoading(true);
    const reqBody = {
      id: data.building_id,
      data:{hpd_alert: event.target.checked},
    };
    mutate(reqBody);
  };
  return (
    <div>
      <label class="custom-checkbox-large">
        <input
          type="checkbox"
          id={`assign-checkbox-${data?.building_id}`}
          onClick={onChange}
          defaultChecked={data?.active}
          // checked={data.mailer_program ? true : false}
          disabled={loading}
        />
        <span
          class="checkmark-large"
          //   style={{ backgroundColor: `${!inspector ? "#ebebeb" : ""}` }}
        ></span>
      </label>
    </div>
  );
}

export default HPDalerts