import React, { useEffect, useState } from "react";
import PrimaryButton from "../../../../../components/Button/Button";
import { Button, CircularProgress } from "@mui/material";
import {
  downloadPendingMailer,
} from "../../../../../sevices/leadComplianceApi";
import toast from "react-hot-toast";
import useDownloader from "react-use-downloader";
import { useCustomSearchParams } from "../../../../../hooks/useCustomSearchParams";
import DownloadIcon from "../../../../../styles/svg/down-arrow-download.svg";

function MailerForReview({ btnTitle, downloadCallback, pdfName }) {
  const [loading, setLoading] = useState(false);
  const { size, elapsed, percentage, download, cancel, error, isInProgress } =
    useDownloader();
  // const taskId = localStorage.getItem("mailer-preview-id");
  const [year, setYear] = useCustomSearchParams("year");
  const [building, setBuilding] = useCustomSearchParams("building");
  const [client, setClient] = useCustomSearchParams("client");

  const downloadMailer = () => {
    setLoading(true);
    downloadCallback({
      building_id: building,
      year: year,
      download_and_send: false,
    })
      .then((res) => {
        download(`${process.env.REACT_APP_BASE_URL}/pdf/${res.data}`, pdfName);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
        setLoading(false);
      });
  };
  // const downloadMailer = () => {
  //   setLoading(true);
  //   downloadMailers({
  //     all_compliance: true,
  //     year: 2025,
  //     compliance_pdf: false,
  //     blanck_pdf: true,
  //   })
  //     .then((res) => {
  //       console.log(res);
  //       localStorage.setItem("mailer-preview-id", res.data.task_id);
  //       toast.success(res.data.message);
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       toast.error(err.response.data.message);
  //       setLoading(false);
  //     });
  // };

  // useEffect(() => {
  //   let intervalId;

  //   // Only set the interval if taskId exists
  //   if (taskId) {
  //     intervalId = setInterval(() => {
  //       console.log(`Task ${taskId} is running...`);
  //       getStatusPdf(taskId)
  //         .then((res) => {
  //           console.log(res);
  //           if (res.data.status == "completed") {
  //             clearInterval(intervalId);
  //             localStorage.removeItem("mailer-preview-id");
  //           }
  //           toast.success(res.data.message);
  //           setLoading(false);
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //           toast.error(err.response.data.message);
  //           setLoading(false);
  //         });
  //     }, 10000); // Run every 10 seconds
  //   }

  //   // Cleanup the interval when taskId becomes false/null or the component unmounts
  //   return () => {
  //     if (intervalId) {
  //       clearInterval(intervalId);
  //       console.log("Interval cleared");
  //     }
  //   };
  // }, [taskId]);

  return (
    <div>
      <PrimaryButton
        variant="contained"
        className={`capitalize btn-bg-orange min-208`}
        onClick={downloadMailer}
        disabled={building && client ? false : true}
        startIcon={
          loading ? (
            <CircularProgress size={20} color="inherit" />
          ) : (
            <img src={DownloadIcon} />
          )
        } // Loader inside button
      >
        {loading ? "" : btnTitle}
      </PrimaryButton>
    </div>
  );
}

export default MailerForReview;
