import React, { useState } from "react";
import { useCustomSearchParams } from "../../../hooks/useCustomSearchParams";
import LongFormDialog from "../../../components/Dialog/LongFormDialog";
import PrimaryButton from "../../../components/Button/Button";
import NoticeForm from "./NoticeForm";


function EnterNoticeResponse() {
  const [anchorEl, setAnchorEl] = useState(false);
  const [refetch, setRefetch] = useCustomSearchParams("refetch");

  const handleClose = () => {
    setAnchorEl(false);
    setRefetch("refetch", refetch ? refetch + 1 : 1);
  };
  const handleClick = () => {
    setAnchorEl(true);
  };
  return (
    <div>
      {anchorEl && (
        <LongFormDialog
          open={anchorEl}
          handleClose={handleClose}
          title={"Notice Response"}
          btnTitle={"Confirm"}
        >
          <NoticeForm
            onClose={handleClose}
            initialData={{ response_date: new Date() }}
          />
        </LongFormDialog>
      )}
      <PrimaryButton
        variant="contained"
        className={`capitalize green-clr`}
        onClick={handleClick}
      >
        Enter Notice Response
      </PrimaryButton>
    </div>
  );
}

export default EnterNoticeResponse;
