import React, { useState } from "react";
import { CircularProgress } from "@mui/material";
import useDownloader from "react-use-downloader";
import toast from "react-hot-toast";

import PrimaryButton from "../../../../components/Button/Button";
import { complianceReportDelete } from "../../../../sevices/leadComplianceApi";

function ReportActions({ url, name, id, handleClose, callback, type,api }) {
  const { download } = useDownloader();
  const [loading, setLoading] = useState();

  const downloadReport = () => {
    download(process.env.REACT_APP_BASE_URL + url, name);
  };
  const Delete = () => {
    setLoading(true);
    api(id, type)
      .then((res) => {
        callback(res);
        handleClose();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div className="d-flex">
      <PrimaryButton
        variant="outlined"
        className="capitalize popup-header-button"
        onClick={Delete}
      >
        {loading ? (
          <p className="d-flex">
            <CircularProgress size={18} color="inherit" className="mr-2"/>
            Deleting Report
          </p>
        ) : (
          "Delete Report"
        )}
      </PrimaryButton>
      <PrimaryButton
        variant="contained"
        className="capitalize btn-bg-orange popup-header-button"
        onClick={downloadReport}
      >
        Download
      </PrimaryButton>
    </div>
  );
}

export default ReportActions;
