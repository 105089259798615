import React from "react";
import MailerProgram from "../Building/MailerProgram";

function Mailer({title, children }) {
  return (
    <div className="d-flex mt-2">
      {children}
      <p className="ml-1 mailer-txt-unit">{title}</p>
    </div>
  );
}

export default Mailer;
