import { objectToFormData } from "../utils/helpers";
import Api from "./axios";


export const downloadPendingMailerFireNotice = async (data) => {
  return await Api.post(`/v1/fire_notices/downloadPendingMailer`, data);
};

export const addFireNotice = async (data) => {
  return await Api.post(`/v1/fire_notices/`, data);
};

export const getFireNotiesCompliance = async (query) => {
  return await Api.get(`/v1/fire_notices${query}`);
};

export const updateFireNotiesCompliance = async (data) => {
  return await Api.put(
    `/v1/fire_notices/${data.survey_id}`,
    objectToFormData(data.data)
  );
};

export const downloadAuditReportFire = async (data) => {
  return await Api.post(`/v1/fire_notices/downloadAuditReport`, data);
};

export const getBuildingByNoticeId = async (survey, token) => {
  return await Api.get(`/v1/fire_notices/by_notice/${survey}`, {
    headers: token ? { authorization: `Bearer ${token}` } : {},
  });
};

export const noticeReportUpload = async (body) => {
  return await Api.post(
    `/v1/fire_notices/reportUpload`,
    objectToFormData(body)
  );
};

export const fireReportDelete = async (id) => {
  return await Api.delete(
    `/v1/fire_notices/reportDeleted?fire_notice_id=${id}`
  );
};


export const downloadFireSurvey = async (data) => {
  return await Api.post(`/v1/fire_notices/downloadSurveyForm`, data);
};