import RedirectToClient from "../../Tickets/components/RedirectToClient";
import ApartmentNumberColumns from "./ApartmentNumberColumns";
import FireComplianceColumns from "./FireComplianceColumns";
import ViewSurvey from "./ViewSurvey";

export const columns = [
  {
    field: "client",
    headerName: "Client",
    renderCell: (params) => {
      return <RedirectToClient params={params} />;
    },
    minWidth: 100,
    flex: 1,
    // editable: true,
    // sortable: false,
  },
  {
    field: "building_address",
    headerName: "Building",
    renderCell: (params) => {
      return <p className="">{params.value}</p>;
    },
    minWidth: 110,
    flex: 1.5,
    // editable: true,
    // sortable: false,
  },

  {
    field: "unit_number",
    headerName: "Unit",
    renderCell: (params) => {
      return <ApartmentNumberColumns params ={params}/>;
    },
    minWidth: 100,
    flex: 0.7,
    // editable: true,
    // sortable: false,
  },
  {
    field: "mailer_count",
    headerName: "Notices Send",
    renderCell: (params) => {
      return <p className="">{params.value}</p>;
    },
    minWidth: 100,
    flex: 0.7,
    // editable: true,
    sortable: false,
  },
  {
    field: "fire_notice",
    headerName: "Fire Compliance",
    renderCell: (params) => {
      return <FireComplianceColumns params={params} />;
    },
    minWidth: 100,
    flex: 0.7,
    // editable: true,
    sortable: false,
  },

  {
    field: "survey",
    headerName: "Survey",
    renderCell: (params) => {
      return <ViewSurvey params={params} />;
    },
    renderHeader: (cell) => (
      <div className="w-100 d-flex justify-content-center">
        <p className="size-14 text-color weight-500">Survey</p>
      </div>
    ),
    minWidth: 110,
    flex: 0.8,
    // editable: true,
    sortable: false,
    headerClassName: "center-header ",
  },
];
