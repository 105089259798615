import React from "react";
import { Box, Grid, Stack } from "@mui/material";

import unitGreen from "../../../../../styles/svg/green-unit.svg";
import unitRed from "../../../../../styles/svg/red-unit.svg";
import frictionRed from "../../../../../styles/svg/red-friction.svg";
import frictionGreen from "../../../../../styles/svg/green-friction.svg";
import Mailer from "./Mailer";
import { useParams } from "react-router-dom";
import MailerProgram from "../Building/MailerProgram";
import { addLeadCompliance } from "../../../../../sevices/leadComplianceApi";
import { addFireNotice } from "../../../../../sevices/fireNoticeApi";
import HPDalerts from "./HPDalerts";

function Heading({ detail }) {
    const param = useParams();
      const role = JSON.parse(localStorage.getItem("userData")).role;
  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={2}
          className="d-flex align-items-center justify-content-space-between"
        >
          <Grid item xs={12} md={6}>
            <p className="page-heading-name">{detail?.address}</p>
            <p className="building-info">
              Click on APT # to view All reports for that unit.
            </p>
            {role == "admin" && (
              <div className="d-flex">
                {detail && (
                  <>
                    <Mailer title={"Include building in mailer program"}>
                      <MailerProgram
                        data={{
                          building_id: param.buildingId,
                          active: detail?.mailer_program || false,
                        }}
                        apiCallback={addLeadCompliance}
                        programKey={"mailer_program"}
                      />
                    </Mailer>
                    <div className="mr-4"></div>
                    <Mailer title={"Include building in Fire Compliance"}>
                      <MailerProgram
                        data={{
                          building_id: param.buildingId,
                          active: detail?.fire_notice_active || false,
                        }}
                        apiCallback={addFireNotice}
                        programKey="fire_notice_active"
                      />
                    </Mailer>
                    <div className="mr-4"></div>
                    <Mailer title={"Enable HPD alerts"}>
                      <HPDalerts
                        data={{
                          building_id: param.buildingId,
                          active: detail?.hpd_alert || false,
                        }}
                      />
                    </Mailer>
                  </>
                )}
              </div>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            className="d-flex align-items-center justify-content-end"
          >
            <div className="d-flex align-items-center mr-4">
              <img src={unitGreen} className="mr-2" />
              <p className="unit-service-icons">XRF - Entire Unit Negative</p>
            </div>
            <div className="d-flex align-items-center mr-4">
              <img src={unitRed} className="mr-2" />
              <p className="unit-service-icons">XRF - Entire Unit Positive</p>
            </div>
            <div className="d-flex align-items-center mr-4">
              <img src={frictionGreen} className="mr-2" />
              <p className="unit-service-icons">XRF - Friction Negative</p>
            </div>

            <div className="d-flex align-items-center mr-4">
              <img src={frictionRed} className="mr-2" />
              <p className="unit-service-icons">XRF - Friction Positive</p>
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default Heading;
