import React, { useState } from "react";
import ShowIcon from "../../../styles/svg/eye-read.svg";
import LongFormDialog from "../../../components/Dialog/LongFormDialog";
import ViewDetails from "./ViewDetails";

function ShowDetails({ params }) {
  const [anchorEl, setAnchorEl] = useState(false);

  const handleClose = () => {
    setAnchorEl(false);
  };
  const handleClick = () => {
    setAnchorEl(true);
  };
  return (
    <>
      <LongFormDialog
        open={anchorEl}
        handleClose={handleClose}
        title={"Ticket Details"}
        btnTitle={"Confirm"}
      >
        <ViewDetails onClose={handleClose} data={params.row} />
      </LongFormDialog>
      <img
        src={ShowIcon}
        className="ml-1 cursor-pointer"
        onClick={handleClick}
      />
    </>
  );
}

export default ShowDetails;
