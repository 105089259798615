import React, { useEffect, useState } from 'react'
import ComplianceYear from '../LeadCompliance/components/Header/ComplianceYear';
import { useCustomSearchParams } from '../../hooks/useCustomSearchParams';
import RightFilters from '../LeadCompliance/components/Filters/RightFilters';
import MailerToSend from '../LeadCompliance/components/Header/Download/MailerToSend';
import { downloadPendingMailerFireNotice } from '../../sevices/fireNoticeApi';
import MailerForReview from '../LeadCompliance/components/Header/Download/MailerForReview';
import EnterNoticeResponse from './components/EnterNoticeResponse';

function Header() {
     const [year, setYear] = useCustomSearchParams("year");
     const currentYear = new Date().getFullYear();
     const [isPastYear, setPastYear] = useState(false);

     useEffect(() => {
       setPastYear(currentYear > year);
     }, [year]);
  return (
    <div className="d-flex justify-content-space-between">
      <div className="d-flex">
        <ComplianceYear name={"Compliance Year"} />
        {isPastYear || <EnterNoticeResponse />}
      </div>
      {isPastYear ? (
        ""
      ) : (
        <div className="d-flex">
          <RightFilters />
          <MailerToSend
            btnTitle={"Survey to Send"}
            downloadCallback={downloadPendingMailerFireNotice}
            pdfName={"annual-report-survey-to-send.pdf"}
          />
          <MailerForReview
            btnTitle={"Survey for Review"}
            downloadCallback={downloadPendingMailerFireNotice}
            pdfName={"annual-report-fire-notice-for-review.pdf"}
          />
        </div>
      )}
    </div>
  );
}

export default Header