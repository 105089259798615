import React, { useEffect, useState } from 'react'
import {
  TextField,
  Select,
  MenuItem,
  Menu,
  FormControl,
  InputLabel,
  Button,
  Grid,
  Stack,
  FormHelperText,
  InputAdornment,
} from "@mui/material";

import Vertical from "../../../styles/svg/verticle-fire-notice.svg";
import useCustomForm from '../../../hooks/useForm';
import InputFileUpload from '../../LeadCompliance/components/Header/UploadButton';
import CustomDatePicker from '../../../components/DatePickerNew';
import moment from 'moment';
import useCustomMutate from '../../../hooks/useMutate';
import { getBuildingByNoticeId, updateFireNotiesCompliance } from '../../../sevices/fireNoticeApi';
import { debounce } from 'lodash';


function NoticeForm({
  initialData = {},
  onClose,
  isShow = false,
  isFromQr = false,
  token = "",
}) {
    const {
      register,
      handleSubmit,
      errors,
      onSubmit,
      handleReset,
      setValue,
      watch,
      setError,
      clearErrors,
      isValid,
      getValues,
      reset,
      trigger,
    } = useCustomForm(initialData);
     const [isRequiredUpload, setIsRequiredUpload] = useState(true);
      const [survey, setSurvey] = useState(initialData?.survey_id || "");
      const [surveyError, setSurveyError] = useState("");
      const { mutate } = useCustomMutate(updateFireNotiesCompliance,
        [],
        () => {

          onClose?.();
        }
      );

      useEffect(() => {
        const getSurvey = debounce(() => {
          getBuildingByNoticeId(survey, token)
            .then((res) => {
              setIsRequiredUpload(
                !res?.data?.data?.response_data?.survey_exist
              );
              // setData(res?.data?.data?.response_data?.unit_compliance);
              // isFromQr &&
              //   setFormState(res?.data?.data?.response_data?.survey_exist);
              !isFromQr
                ? reset({
                    ...res?.data?.data?.response_data?.fire_notice,
                    response_date: res?.data?.data?.response_data?.fire_notice
                      ?.response_date
                      ? new Date(
                          res?.data?.data?.response_data?.fire_notice?.response_date
                        )
                      : new Date(),
                    building_address:
                      res?.data?.data?.response_data?.building_address,
                  })
                : setValue(
                    "building_address",
                    res?.data?.data?.response_data?.building_address
                  );
            })

            .catch((err) => {
              isFromQr || setError("survey_id", err?.response?.data?.message);
              isFromQr || setSurveyError(err?.response?.data?.message);
            });
        }, 700);

        if (survey) {
          survey && setSurveyError("");
          survey && clearErrors("survey_id");
          getSurvey();
        } else {
          setError("survey_id", "Survey Id is Required");
          setSurveyError("Survey Id is Required");
        }

        return () => {
          // Cleanup the debounce function on component unmount
          survey && setSurveyError("");
          survey && clearErrors("survey_id");
          setValue("building_address", "");
          getSurvey.cancel();
        };
      }, [survey]);

       const handleChange = (e) => {
         setSurvey(e.target.value);
       };

      const submit = (data) => {
        const payload = Object.fromEntries(
          Object.entries(data).filter(([key, value]) => value !== null)
        );
        payload["file"] = payload?.attachment?.[0];
        delete payload.attachment;
        payload.file || delete payload.file;

        mutate({
          data: {
            ...payload,
            response_date: moment(payload.response_date).format("YYYY-MM-DD"),
          },
          survey_id: survey,
          token,
        });
      };

      const fields = [
        {
          name: "fire_safety_required",
          value:false,
          engText:
            "Yes, the fire safety notice is posted on the interior of my entrance door",
        },
        {
          name: "fire_safety_required",
          value:true,
          engText: "NO, the fire safety notice is not posted or is damaged.",
        },
      ];

      const surveyDetailsFields = [
        { name: "tenant_name", label: "Tenants Name", type: "text" },
        { name: "response_date", label: "Date (Fecha)", type: "date" },
      ];
  return (
    <div className="survey-form-container">
      <form onSubmit={handleSubmit(submit)}>
        <Stack>
          <Grid container spacing={4}>
            {!isFromQr && (
              <Grid item xs={12} md={4} className="d-flex align-items-center">
                <p className="label-field">Notice ID:</p>
                {isShow ? (
                  <p className="survey-id-text">{initialData?.survey_id}</p>
                ) : (
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    error={!!surveyError}
                    helperText={surveyError}
                    defaultValue={initialData?.survey_id}
                    onChange={handleChange}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#16304f", // Change this to your desired focus color
                        },
                      },
                      "& .MuiInputLabel-root": {
                        "&.Mui-focused": {
                          color: "#16304f", // Change this to your desired label focus color
                          fontFamily: "segoe_uisemibold",
                          fontSize: "13px",
                        },
                      },
                    }}
                  />
                )}
              </Grid>
            )}
            <Grid
              item
              xs={12}
              md={isFromQr || isShow ? 8 : 5}
              className="d-flex align-items-center"
            >
              <p className="label-field">Address:</p>
              <p className="survey-id-text">
                {watch("building_address") || ""}
              </p>
            </Grid>
            {!isShow && (
              <Grid
                item
                xs={12}
                md={3}
                className="d-flex justify-content-end align-items-center"
              >
                <InputFileUpload
                  watch={watch}
                  register={register}
                  getValues={getValues}
                  errors={errors}
                  isRequiredUpload={isRequiredUpload}
                />
              </Grid>
            )}
          </Grid>
          <Grid className="d-flex mt-4">
            <div className="verticle-div">
              <img src={Vertical} />
            </div>
            <div className="horizontal-div mt-4 ml-2">
              {fields.map((el, idx) => (
                <div key={`checkbox-survey-${idx}`} className="mb-5">
                  <div className="check-parent d-flex">
                    <div className="mr-2">
                      <label class="custom-checkbox-large">
                        <input
                          type="radio"
                          //   value={el.value === watch(el.name)}
                          checked={el.value === watch(el.name) ? true : false}
                          onChange={() => setValue(el.name, el.value)}
                          //   {...register(el.name)}
                        />
                        <span class="checkmark-large-radio"></span>
                      </label>
                    </div>
                    <div>
                      <p className="eng-language-600">{el.engText}</p>
                    </div>
                  </div>
                </div>
              ))}
              <div className="mt-4 mb-5">
                <p className="span-language">
                  I hereby certify that the information provided above regarding
                  the posting of the Fire Safety Notice in my apartment is
                  accurate. I understand that providing false information is
                  punishable by law.
                </p>
              </div>
              <Grid container spacing={4}>
                {surveyDetailsFields.map((el, idx) => (
                  <Grid
                    item
                    xs={12}
                    md={12}
                    className="d-flex align-items-center"
                    key={`survey-details-fields-${idx}`}
                  >
                    <p className="label-field">{el.label}</p>
                    {el.type == "date" ? (
                      <CustomDatePicker
                        value={watch(el.name)}
                        defaultValue={initialData[el.name] || ""}
                        errors={errors}
                        disabled={true}
                        field={el}
                        setValue={setValue}
                        {...register(el.name, {
                          required: `${el.label} is required`,
                        })}
                        // isShring={true}
                        // placeholder={el.placeholder}
                      />
                    ) : (
                      <TextField
                        fullWidth
                        {...register(
                          el.name,
                          isFromQr && {
                            required: `${el.label} is required`,
                          }
                        )}
                        InputLabelProps={{ shrink: true }}
                        error={!!errors[el.name]}
                        helperText={errors[el.name]?.message}
                        disabled={isShow}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "#16304f", // Change this to your desired focus color
                            },
                          },
                          "& .MuiInputLabel-root": {
                            "&.Mui-focused": {
                              color: "#16304f", // Change this to your desired label focus color
                              fontFamily: "segoe_uisemibold",
                              fontSize: "13px",
                            },
                          },
                        }}
                      />
                    )}
                  </Grid>
                ))}
              </Grid>
            </div>
          </Grid>

          <Stack
            sx={{
              alignSelf: "end",
            }}
            direction={"row"}
            spacing={2}
            className="popup-footer-border"
          >
            <Button
              variant="outlined"
              className="secondary-btn-class"
              onClick={onClose}
              // disabled={loading}
            >
              Cancel
            </Button>
            {/* //capitalize h-42 */}
            <Button
              type="submit"
              variant="contained"
              className={ "primary-btn-class"}
              //   disabled={disableSubmitForm()}
              sx={{ boxShadow: "none" }}
            >
              Submit
            </Button>
          </Stack>
        </Stack>
      </form>
    </div>
  );
}

export default NoticeForm