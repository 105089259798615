import React, { useEffect, useState } from "react";
import {
  TextField,
  Select,
  MenuItem,
  Menu,
  FormControl,
  InputLabel,
  Button,
  Grid,
  Stack,
  FormHelperText,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import { debounce } from "lodash";

import useCustomForm from "../../../hooks/useForm";
import CustomDatePicker from "../../../components/DatePicker";
import ToggleInput from "../../../components/ToggleInput";
import { useBuildings } from "../../../reactQuery/building";
import { useServices } from "../../../reactQuery/service";
import CheckBoxInstruction from "./CheckBoxInstruction";
import {
  checkDuplicateJobId,
  createJobs,
  isAvailableDate,
  updateJobs,
  uploadReport,
} from "../../../sevices/jobApi";
import useCustomMutate from "../../../hooks/useMutate";
import ViewTerms from "./ViewTerms";

import { useInspector } from "../../../reactQuery/inspector";
import SearchList from "../../../components/Search/SearchList";
import { useReportOptions } from "../../../reactQuery/job";
import { objectToFormData } from "../../../utils/helpers";
import DownArrow from "../../../styles/svg/down-arrow.svg";
import { useCalendar } from "../../../reactQuery/calendar";
import toast from "react-hot-toast";
import BasicTimePicker from "../../../components/BasicTimePicker";

const InputField = styled("input")({
  display: "none",
});

const UploadButton = styled(Button)(({ theme }) => ({
  border: `1px dashed ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  backgroundColor: theme.palette.background.paper,
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

function TicketForm({
  initialValues,
  onClose,
  isEdit,
  isFromClient,
  isReSchedule,
}) {
  const role = JSON.parse(localStorage.getItem("userData")).role;
  const {
    register,
    handleSubmit,
    errors,
    onSubmit,
    handleReset,
    setValue,
    watch,
    control,
    setError,
    clearErrors,
    isValid,
    restFormState,
    Controller,
  } = useCustomForm(initialValues);
  const [searchQuery, setSearchQuery] = useState("");

  const dates = {
    start_date: moment().subtract(12, "months"),
    end_date: moment().add(6, "months"),
  };
  const {
    isLoading,
    error,
    responseData: dateInfo,
    isError,
    refetch,
  } = useCalendar(dates);
  const { responseData, unitStatus } = useBuildings();
  const { responseData: services } = useServices();
  const { responseData: inspectors } = useInspector();
  const { responseData: reportOptions } = useReportOptions();
  const formValues = watch();

  useEffect(() => {
    if (!isLoading) refetch();
  }, []);

  const { mutate } = useCustomMutate(
    isEdit ? updateJobs : createJobs,
    [
      "jobs",
      "calendar",
      "tickets-pending",
      "tickets-recent-uploads",
      "tickets-no-access",
    ],
    onClose
  );
  const { mutate: uploadDocument } = useCustomMutate(uploadReport, []);

  const [apt, setApt] = useState([]);
  const [checkedTerms, setTerms] = useState(!isEdit);

  const buildingSelect = watch("building_id");
  const aptSelect = watch("apartment");
  const selectedScheduledDate = watch("scheduled_date");
  const ticketStatus = watch("report_status");
  const fileDocument = watch("report_name");
  const file = watch("file");

  useEffect(() => {
    // const notEqual = ["Pending", "No Access", "Cancelled"];
    // if (!notEqual.includes(ticketStatus) && !fileDocument) {
    //   setError("report_name", {
    //     type: "required",
    //     message: "Report Upload field is required",
    //   });
    // } else
    if (file && file?.size > 20 * 1024 * 1024) {
      setError("report_name", {
        type: "validate",
        message: "File size should be less than 20MB",
      });
    } else {
      clearErrors("report_name");
    }
  }, [ticketStatus, fileDocument]);

  useEffect(() => {
    if (
      initialValues.scheduled_date != selectedScheduledDate &&
      role != "admin"
    ) {
      isAvailableDate(selectedScheduledDate)
        .then((res) => {
          res.data.data.is_available
            ? clearErrors("scheduled_date")
            : setError("scheduled_date", {
                type: "manual",
                message: res.data.message,
              });
        })
        .catch((err) => console.log(err));
    } else {
      clearErrors("scheduled_date");
    }
  }, [selectedScheduledDate]);

  React.useEffect(() => {
    if (aptSelect) {
      setValue(
        "unit_size",
        apt.find((el) => el.unit_number == aptSelect)?.unit_size
      );
      setValue(
        "unit_id",
        apt.find((el) => el.unit_number == aptSelect)?.unit_id
      );
      setValue(
        "unit_status",
        apt.find((el) => el.unit_number == aptSelect)?.unit_status
      );
    }
  }, [aptSelect]);

  React.useEffect(() => {
    if (buildingSelect) {
      const currentBuilding = responseData.find(
        (el) => el.building_id == buildingSelect
      );
      setApt(currentBuilding?.units || []);
      setValue("unit_size", "");
      // setValue("borough", currentBuilding?.borough);
      // setValue("superintendent_name", currentBuilding?.superintendent_name);
      // setValue("superintendent_number", currentBuilding?.superintendent_number);
      if (currentBuilding) {
        setValue("borough", currentBuilding.borough || "");
        setValue(
          "superintendent_name",
          currentBuilding.superintendent_name || ""
        );
        setValue(
          "superintendent_number",
          currentBuilding.superintendent_number || ""
        );
      } else {
        setValue("borough", "");
        setValue("superintendent_name", "");
        setValue("superintendent_number", "");
      }
    }
  }, [buildingSelect]);

  useEffect(() => {
    handleReset(); // Prefill form when initialValues change
  }, [initialValues]);

  //- Check Job Id Duplicate or not -//

  const ticket_id = watch("ticket_id");

  const Check = 
     debounce(() => {
    checkDuplicateJobId(ticket_id).then((res)=>{
      const {ticket_id_exist} = res.data.data.response_data;
      if (ticket_id_exist) {
        setError("ticket_id", {
          type: "validate",
          message: "Job Id already present, please use a new Job Id.",
        });
      } else {
        clearErrors("ticket_id");
      }

    }).catch((err)=>{

    })},700)
  

  useEffect(() => {
    if (ticket_id && initialValues?.ticket_id !== ticket_id) Check();

    return () => {
      Check.cancel();
    };
  }, [ticket_id]);

  //////////-Close-//////////

  console.log("errors====", isValid,errors);

  const fields = [
    {
      name: "scheduled_date",
      label: "Date",
      type: "date",
      placeholder: "Select date",
    },

    ...(isEdit
      ? [
          {
            name: "inspector_id",
            label: "Inspector",
            type: "select",
            placeholder: "Select inspector",
            isRequired: false,
            disabled: role == "client",
            options: inspectors
              ? inspectors
                  .filter((el) => el.has_permission)
                  .map((el) => {
                    return { label: el.user.name, value: el.inspector_id };
                  })
              : [],
          },
        ]
      : []),
    {
      name: "service_id",
      label: "Service Needed",
      type: "select",
      isRequired: true,
      placeholder: "Select service",
      disabled: role == "client" && isEdit,
      options: services
        ? services
            .filter((el) => el.is_active)
            .map((el) => {
              return { label: el.name, value: el.service_id };
            })
            ?.sort(function (a, b) {
              return a.sequence - b.sequence;
            })
        : [],
    },
    {
      name: "building_id",
      label: "Building",
      type: "select",
      isRequired: true,
      placeholder: "Select building",
      search: true,
      options: responseData
        ? isFromClient
          ? responseData
              .filter((el) => el.client_id == isFromClient)
              .map((el) => {
                return { label: el.address, value: el.building_id };
              })
          : responseData.map((el) => {
              return { label: el.address, value: el.building_id };
            })
        : [],
    },
    {
      name: "apartment",
      label: "Apt#",
      type: "select",
      placeholder: "Select unit",
      isRequired: true,
      search: true,
      options: apt
        .map((el) => {
          return {
            label: el.unit_number,
            value: el.unit_number,
            past_service: el.past_service,
          };
        })
        ?.sort(function (a, b) {
          return a.unit_number - b.unit_number;
        }),
    },
    {
      name: "unit_size",
      label: "Unit Size",
      type: "text",
      disabled: true,
      placeholder: "Select a unit size",
    },
    {
      name: "unit_status",
      label: "Unit Status",
      type: "select",
      placeholder: "Select unit status",
      isRequired: true,
      options: unitStatus.map((el) => {
        return { label: el, value: el };
      }),
    },
    {
      name: "borough",
      label: "Year Built",
      type: "text",
      disabled: true,
      placeholder: "Select Year Built",
    },
    {
      name: "superintendent_name",
      label: "Superintendent Name",
      placeholder: "Enter Superintendent name",
      type: "text",
      disabled: true,
    },
    {
      name: "superintendent_number",
      label: "Contact#",
      placeholder: "Enter contact",
      type: "text",
      disabled: true,
    },
    ...(isEdit
      ? [
          ...(role != "client"
            ? [
                {
                  name: "report_name",
                  label: "Report Upload",
                  type: "file",
                },
              ]
            : []),

          {
            name: "report_status",
            label: "Ticket Status",
            type: "select",
            placeholder: "Select ticket status",
            size: 3,
            options: reportOptions
              ? reportOptions.map((el) => {
                  return { label: el, value: el };
                })
              : [],
          },
          {
            name: "ticket_id",
            label: "Job Id",
            type: "text",
            size: 3,
            isRequired: false,
            disabled: role === "admin" ? false : true,
            placeholder: "Enter Job Id",
          },
          {
            name: "num_of_readings",
            label: "# Of Readings",
            type: "number",
            disabled: role === "admin" ? false : true,
            placeholder: "Select # Of Readings",
          },
          {
            name: "num_of_positives",
            label: "# Of Positives",
            type: "number",
            disabled: role === "admin" ? false : true,
            placeholder: "Select # Of Positives",
          },
          {
            name: "num_of_samples_taken",
            label: "# Of Samples",
            type: "number",
            disabled: role === "admin" ? false : true,
            placeholder: "Select # Of Samples",
          },
          {
            name: "start_time",
            label: "Start Time",
            type: "time",
            disabled: role === "admin" ? false : true,
            placeholder: "Enter Start Time",
          },
          {
            name: "end_time",
            label: "End Time",
            type: "time",
            disabled: role === "admin" ? false : true,
            placeholder: "Enter End Time",
          },
        ]
      : []),
    { name: "is_flexible", label: "Flexible", type: "toggle" },
    {
      name: "additional_comment",
      label: "Additional Comments",
      type: "textarea",
      placeholder: "Start typing...",
    },
  ];

  const submit = (data) => {
    const prepareDate = isEdit
      ? {
          id: initialValues.job_id,
          data: {
            scheduled_date: moment(data.scheduled_date).format("YYYY-MM-DD"),
            client_id: null,
            inspector_id: data.inspector_id || null,
            unit_id: data.unit_id,
            service_id: data.service_id,
            is_flexible: data.is_flexible,
            testing_result: null,
            report_status: data.report_status,
            is_paid: null,
            testing_report: null,
            report_upload_date: null,
            unit_status: data.unit_status,
            attachment: null,
            additional_comment: data.additional_comment,
            is_report: true,
            payment_url: null,
            num_of_readings: data?.num_of_readings || null,
            num_of_positives: data?.num_of_positives || null,
            num_of_samples_taken: data?.num_of_samples_taken || null,
            start_time: data?.start_time || null,
            end_time: data?.end_time || null,
            ticket_id: data?.ticket_id || null,
          },
        }
      : {
          // scheduled_date: data.scheduled_date.toISOString(),
          scheduled_date: moment(data.scheduled_date).format("YYYY-MM-DD"),
          service_id: data.service_id,
          building_id: data.building_id,
          unit_id: data.unit_id,
          borough: data.borough,
          additional_comment: data.additional_comment,
          is_flexible: data.is_flexible,
          unit_status: data.unit_status,
          ...(data?.compliance_id
            ? { compliance_id: data?.compliance_id }
            : {}),
        };
    isEdit &&
      data.file &&
      uploadDocument({
        data: objectToFormData({
          file: data.file,
          report_status: data.report_status,
        }),
        id: initialValues.job_id,
      });
    mutate(prepareDate);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.pdf)$/i; // Specify allowed extensions
      const fileName = file.name;

      // Check if the file has a valid extension
      if (!allowedExtensions.exec(fileName)) {
        toast.error(
          "Invalid file type. Only .jpg, .jpeg, .png, .pdf files are allowed."
        );
        event.target.value = ""; // Clear the input
        return;
      }

      // If valid, set the file name to state
      setValue("file", file);
      setValue("report_name", file.name);
    }
  };

  // [
  //   {
  //     date: moment("09/22/2024").format("YYYY-MM-DD"),
  //     message: "Today is excluded",
  //   },
  //   {
  //     date: moment("09/24/2024").format("YYYY-MM-DD"),
  //     message: "Today is excluded",
  //   },
  // ];

  const disableDates = dateInfo
    ?.filter((el) => {
      if (el.blocked_date) {
        return true;
      } else if (el.job_limit <= el.job_count && role != "admin") {
        return true;
      } else {
        return false;
      }
    })
    ?.map((el) => {
      if (el.blocked_date) {
        return {
          date: new Date(`${el.scheduled_date}T00:00:00`),
          message: "date is blocked",
        };
      } else if (el.job_limit <= el.job_count) {
        return {
          date: new Date(`${el.scheduled_date}T00:00:00`),
          message: "Job limit exceeded for this date.",
        };
      }
    });

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Stack>
        <Grid container spacing={2}>
          {fields.map((field, index) => (
            <Grid
              item
              xs={12}
              md={
                field?.size === 3
                  ? 3
                  : field.type === "time"
                  ? 3
                  : field.type === "textarea"
                  ? 12
                  : field.type === "toggle" && isEdit
                  ? 12
                  : 6
              }
              key={index}
            >
              {field.type === "file" ? (
                <FormControl
                  fullWidth
                  error={errors?.[field.name] ? true : false}
                  // InputLabelProps={{ shrink: true }}
                >
                  <InputLabel shrink>{field.label}</InputLabel>
                  <label htmlFor="upload-file">
                    <InputField
                      accept="*"
                      id="upload-file"
                      type="file"
                      onChange={handleFileChange}
                    />
                    <UploadButton
                      variant="outlined"
                      component="span"
                      className="upload-btn"
                    >
                      {fileDocument ? fileDocument : "Upload"}
                    </UploadButton>
                  </label>
                  <FormHelperText>
                    {errors?.[field.name] && errors?.[field.name].message}
                  </FormHelperText>
                </FormControl>
              ) : field.type === "toggle" ? (
                <div className="d-flex align-items-center h-100">
                  <div>
                    <p className="flexible-heading">Flexible</p>
                    <div className="toggle-btn">
                      <ToggleInput
                        defaultValue={initialValues[field.name] || false}
                        setValue={setValue}
                        name={"is_flexible"}
                      />
                    </div>
                  </div>
                  <p className="instruction-text ml-2">
                    Check this box if the unit is vacant and can be tested PRIOR
                    to your selected date if an opening becomes available (This
                    option is ONLY available for vacant units)
                  </p>
                </div>
              ) : field.type === "date" ? (
                <CustomDatePicker
                  label={field.label}
                  defaultValue={initialValues[field.name] || ""}
                  errors={errors}
                  field={field}
                  setValue={setValue}
                  excludeDates={disableDates}
                  {...register(field.name, {
                    required: `${field.label} is required`,
                  })}
                  isShring={true}
                  placeholder={field.placeholder}
                  disabledPast={role === "admin" ? false : true}
                />
              ) : field.type === "select" ? (
                <FormControl
                  fullWidth
                  error={errors?.[field.name] ? true : false}
                  // InputLabelProps={{ shrink: true }}
                >
                  <InputLabel shrink>{field.label}</InputLabel>
                  <Controller
                    name={field.name}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        {...register(
                          field.name,
                          field.isRequired && {
                            required: `${field.label} is required`,
                          }
                        )}
                        value={value || ""}
                        defaultValue={initialValues[field.name] || ""}
                        className="select-box"
                        displayEmpty
                        //DownArrow
                        // InputProps={{
                        //   endAdornment: <img src={DownArrow} />,
                        // }}
                        disabled={field?.disabled || false}
                        endAdornment={
                          watch(field.name) &&
                          field.name == "inspector_id" && (
                            <InputAdornment position="end">
                              <IconButton
                                edge="end"
                                onClick={() => {
                                  setValue(field.name, "");
                                  onChange("");
                                }} // Clear the value when clicking the clear button
                                aria-label="clear"
                                className="margin-right-1"
                              >
                                <CloseIcon />
                              </IconButton>
                            </InputAdornment>
                          )
                        }
                      >
                        <MenuItem value="" disabled>
                          <em className="placeholder-select">
                            {field.placeholder}
                          </em>
                        </MenuItem>
                        {field.search ? (
                          <SearchList
                            onSearch={(val) => setSearchQuery(val)}
                            searchQuery={searchQuery}
                          />
                        ) : null}
                        {field.search
                          ? field.options
                              ?.filter((item) =>
                                item.label
                                  .toLowerCase()
                                  .includes(searchQuery.toLowerCase())
                              )
                              ?.map((option, idx) => (
                                <MenuItem
                                  value={option.value}
                                  key={idx}
                                  className="dropdown-menu-options"
                                  onClick={() => setSearchQuery("")}
                                >
                                  <p
                                    className={`dropdown-options ${
                                      option.past_service ? "green-color" : ""
                                    }`}
                                  >
                                    {option.label}
                                  </p>
                                </MenuItem>
                              )) || []
                          : field.options?.map((option, idx) => (
                              <MenuItem
                                value={option.value}
                                key={idx}
                                className="dropdown-menu-options"
                                onClick={() => setSearchQuery("")}
                              >
                                <p className="dropdown-options">
                                  {option.label}
                                </p>
                              </MenuItem>
                            )) || []}
                      </Select>
                    )}
                  />
                  <FormHelperText>
                    {errors?.[field.name] && errors?.[field.name].message}
                  </FormHelperText>
                </FormControl>
              ) : field.type === "textarea" ? (
                <TextField
                  label={field.label}
                  multiline
                  rows={3}
                  fullWidth
                  {...register(field.name)}
                  error={!!errors[field.name]}
                  helperText={errors[field.name]?.message}
                  disabled={field.disabled || false}
                  placeholder={field.placeholder}
                  InputLabelProps={{ shrink: true }}
                />
              ) : field.type === "time" ? (
                <BasicTimePicker
                  label={field.label}
                  disabled={field.disabled || false}
                  control={control}
                  Controller={Controller}
                  setValue={setValue}
                  name={field.name}
                  watch={watch}
                />
              ) : field.type === "number" ? (
                <TextField
                  label={field.label}
                  type="number"
                  fullWidth
                  {...register(field.name)}
                  InputLabelProps={{ shrink: true }}
                  error={!!errors[field.name]}
                  helperText={errors[field.name]?.message}
                  disabled={field.disabled || false}
                  placeholder={field.placeholder}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#16304f", // Change this to your desired focus color
                      },
                    },
                    "& .MuiInputLabel-root": {
                      "&.Mui-focused": {
                        color: "#16304f", // Change this to your desired label focus color
                        fontFamily: "segoe_uisemibold",
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              ) : (
                <TextField
                  label={field.label}
                  fullWidth
                  {...register(field.name, field?.isRequired !== false && {
                    required: `${field.label} is required`,
                  })}
                  InputLabelProps={{ shrink: true }}
                  error={!!errors[field.name]}
                  helperText={errors[field.name]?.message}
                  disabled={field.disabled || false}
                  placeholder={field.placeholder}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#16304f", // Change this to your desired focus color
                      },
                    },
                    "& .MuiInputLabel-root": {
                      "&.Mui-focused": {
                        color: "#16304f", // Change this to your desired label focus color
                        fontFamily: "segoe_uisemibold",
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              )}
            </Grid>
          ))}
          <Grid item xs={12} md={12}>
            <div className="d-flex">
              <CheckBoxInstruction
                callback={(check) => setTerms(!check)}
                defaultValue={isEdit}
              />
              <p className="instruction-text d-flex">
                By checking this box you agree to our service terms, to view
                service terms click here. <ViewTerms />
              </p>
            </div>
          </Grid>
        </Grid>
        {/* <Grid className="d-flex justify-content-end "> */}
        <Stack
          sx={{
            alignSelf: "end",
          }}
          direction={"row"}
          spacing={2}
          className="popup-footer-border"
        >
          <Button
            variant="outlined"
            className="secondary-btn-class"
            onClick={onClose}
          >
            Cancel
          </Button>
          {isReSchedule ? (
            <Button
              type="submit"
              variant="contained"
              className={checkedTerms ? "capitalize h-42" : "primary-btn-class"}
              disabled={checkedTerms}
              sx={{ boxShadow: "none" }}
            >
              Submit
            </Button>
          ) : (
            <Button
              type="submit"
              variant="contained"
              className={
                checkedTerms || !isValid
                  ? "capitalize h-42"
                  : "primary-btn-class"
              }
              disabled={checkedTerms || !isValid}
              sx={{ boxShadow: "none" }}
            >
              Submit
            </Button>
          )}
        </Stack>
      </Stack>
      {/* </Grid> */}
    </form>
  );
}

export default TicketForm;
