import React from 'react'
import IsRequired from '../../LeadCompliance/components/Table/IsRequired';
import Report from '../../LeadCompliance/components/Table/Report';
import ReportFireNotice from './ReportFireNotice';

function FireComplianceColumns({ params, refetchUnits }) {
  const {
    fire_safety_required = false,
    fire_safety_report_attachment =false,
    fire_notice_id,
    status,
  } = params.value || {};

  return status == "pending" ? (
    ""
  ) : (
    <IsRequired
      required={fire_safety_required}
      attachment={fire_safety_report_attachment}
    >
      <ReportFireNotice
        id={fire_notice_id}
        attachment={fire_safety_report_attachment}
        refetchUnits={refetchUnits}
        status={status}
      />
    </IsRequired>
  );
}

export default FireComplianceColumns