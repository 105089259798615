import React, { useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import PrimaryButton from "../../../../../components/Button/Button";
import {
  downloadPendingMailer,
} from "../../../../../sevices/leadComplianceApi";
import toast from "react-hot-toast";
import useDownloader from "react-use-downloader";
import { useCustomSearchParams } from "../../../../../hooks/useCustomSearchParams";
import { useQueryClient } from "@tanstack/react-query";
import DownloadIcon from "../../../../../styles/svg/down-arrow-download.svg";

function MailerToSend({ btnTitle, downloadCallback, pdfName }) {
  const [loading, setLoading] = useState(false);
  const { size, elapsed, percentage, download, cancel, error, isInProgress } =
    useDownloader();
  const [building, setBuilding] = useCustomSearchParams("building");
  const [client, setClient] = useCustomSearchParams("client");
  const [year, setYear] = useCustomSearchParams("year");
  const [refetch, setRefetch] = useCustomSearchParams("refetch");

  const downloadMailer = () => {
    setLoading(true);
    downloadCallback({
      building_id: building,
      year: year,
      download_and_send: true,
    })
      .then((res) => {
        setRefetch("refetch", refetch ? refetch + 1 : 1);
        download(`${process.env.REACT_APP_BASE_URL}/pdf/${res.data}`, pdfName);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
        setLoading(false);
      });
  };
  return (
    <div className="mr-2">
      {" "}
      <PrimaryButton
        variant="contained"
        className={`capitalize blue-clr min-208`}
        onClick={downloadMailer}
        disabled={building && client ? false : true}
        startIcon={
          loading ? (
            <CircularProgress size={20} color="inherit" />
          ) : (
            <img src={DownloadIcon} />
          )
        } // Loader inside button
      >
        {loading ? "" : btnTitle}
      </PrimaryButton>
    </div>
  );
}

export default MailerToSend;
