import React, { useEffect, useState } from "react";
import {
  TextField,
  Select,
  MenuItem,
  Menu,
  FormControl,
  InputLabel,
  Button,
  Grid,
  Stack,
  FormHelperText,
  InputAdornment,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import CheckIcon from "@mui/icons-material/Check";
import Vertical from "../../../../styles/svg/vertical.svg";
import UploadSurvey from "../../../../styles/svg/upload-survey.svg";
import useCustomForm from "../../../../hooks/useForm";
import PrimaryButton from "../../../../components/Button/Button";
import InputFileUpload from "./UploadButton";
import { fields, surveyDetailsFields } from "./SurveyFields";
import useCustomMutate from "../../../../hooks/useMutate";
import {
  addSurveyCompliance,
  scanSurveyCompliance,
} from "../../../../sevices/leadComplianceApi";
import { getBuildingBySurvey } from "../../../../sevices/buildingApi";
import { debounce } from "lodash";
import CustomDatePicker from "../../../../components/DatePickerNew";
import moment from "moment";
import {
  deFormatPhoneNumber,
  formatPhoneNumber,
} from "../../../../utils/helpers";
import SignatureForm from "../../../SurveyScan/SignatureForm";
import CustomSelectDropdown from "../../../Client/components/DropDownFilter";


function EnterSurveyForm({
  initialData = {},
  onClose,
  isShow = false,
  isFromQr = false,
  token = "",
}) {
  const [isAlreadySubmitted, setFormState] = useState("");
  const [message, setMessage] = useState(
    "This Survey response has already been submitted."
  );
  const {
    register,
    handleSubmit,
    errors,
    onSubmit,
    handleReset,
    setValue,
    watch,
    setError,
    clearErrors,
    isValid,
    getValues,
    reset,
    trigger,
  } = useCustomForm(initialData);
  const { mutate } = useCustomMutate(
    isFromQr ? scanSurveyCompliance : addSurveyCompliance,
    [],
    () => {
      setFormState(true);
      onClose?.();
      setMessage("This survey response has been submitted.");
    }
  );

  const [survey, setSurvey] = useState(initialData?.survey_id || "");
  const [surveyError, setSurveyError] = useState("");
  const [isRequiredUpload, setIsRequiredUpload] = useState(true);
  const [data,setData] = useState({});


  const disableSubmitForm = ()=>{
    const {
      knob_covers_report_attachment = false,
      window_guard_report_attachment = false,
      compliance_report_attachment = false,
    } = data || {};

    return (
      knob_covers_report_attachment ||
      window_guard_report_attachment ||
      compliance_report_attachment
    );
  }


  useEffect(() => {
    const getSurvey = debounce(() => {
      getBuildingBySurvey(survey, token)
        .then((res) => {
          setIsRequiredUpload(!res?.data?.data?.response_data?.survey_exist);
          setData(res?.data?.data?.response_data?.unit_compliance);
          isFromQr &&
            setFormState(res?.data?.data?.response_data?.survey_exist);
          !isFromQr
            ? reset({
                ...res?.data?.data?.response_data?.unit_compliance,
                dates: res?.data?.data?.response_data?.unit_compliance
                  ?.response_date
                  ? new Date(
                      res?.data?.data?.response_data?.unit_compliance?.response_date
                    )
                  : new Date(),
                building_address: res?.data?.data?.response_data?.building_address,
              })
            : setValue(
                "building_address",
               res?.data?.data?.response_data?.building_address
              );
        })

        .catch((err) => {
          isFromQr || setError("survey_id", err?.response?.data?.message);
          isFromQr || setSurveyError(err?.response?.data?.message);
        });
    }, 700);

    if (survey) {
      survey && setSurveyError("");
      survey && clearErrors("survey_id");
      getSurvey();
    } else {
      setError("survey_id", "Survey Id is Required");
      setSurveyError("Survey Id is Required");
    }

    return () => {
      // Cleanup the debounce function on component unmount
      survey && setSurveyError("");
      survey && clearErrors("survey_id");
      setValue("building_address", "");
      getSurvey.cancel();
    };
  }, [survey]);

  const window_guards_installed = watch("window_guards_installed");
  useEffect(() => {
    if (
      [true, false, "repair"].includes(window_guards_installed) 
    )
      setValue("child_under_10", true);

  }, [window_guards_installed]);

  const child_under_10 = watch("child_under_10");
   useEffect(() => {
     if (child_under_10 == false){
       setValue("window_guards_installed", null);
       setValue("window_guards_need_repair", null);
     }
   }, [child_under_10]);

  const window_guards_installed_anyway = watch(
    "window_guards_installed_anyway"
  );
  useEffect(() => {
    if (
      window_guards_installed_anyway == true ||
      window_guards_installed_anyway == false
    )
      setValue("child_under_10_not_living", true);
  }, [window_guards_installed_anyway]);

  const child_under_10_not_living = watch("child_under_10_not_living");
  useEffect(() => {
    if (child_under_10_not_living == false)
      setValue("window_guards_installed_anyway", null);
  }, [child_under_10_not_living]);

  const window_guards_need_repair = watch("window_guards_need_repair");
  useEffect(() => {
    if (window_guards_need_repair === true)
      setValue("window_guards_installed", "repair");
  }, [window_guards_need_repair]);

  const stove_knob_covers = watch("stove_knob_covers");
  useEffect(() => {
    if (
      (stove_knob_covers == true || stove_knob_covers == false) &&
      watch("child_under_6") == null
    )
      setValue("child_under_6", true);
  }, [stove_knob_covers]);

  const child_under_6 = watch("child_under_6");
  useEffect(() => {
    if (child_under_6 == null) setValue("stove_knob_covers", null);
  }, [child_under_6]);

  const handleChange = (e) => {
    setSurvey(e.target.value);
  };

  const underSix = watch("child_under_6");

  const submit = (data) => {
    const payload = Object.fromEntries(
      Object.entries(data).filter(([key, value]) => value !== null)
    );
    const needRepair =
      payload.window_guards_installed === "repair"
        ? {
            window_guards_need_repair: true,
          }
        : {};

      if (payload.window_guards_installed === "repair"){
        delete payload.window_guards_installed;
      }else if ([true, false].includes(payload.window_guards_installed)) {
        delete payload?.window_guards_need_repair;
      }


        delete payload.building_address;
    const attachment = data?.attachment?.[0];
    delete payload?.attachment;
    mutate({
      data: {
        ...payload,
        ...(attachment
          ? {
              attachment: attachment,
            }
          : {}),
        // ...(signatureAttachment
        //   ? {
        //       signature_attachment: signatureAttachment,
        //     }
        //   : {}),
        ...needRepair,

        ...(payload.window_guards_installed_anyway == true
          ? {
              window_guards_installed_anyway: true,
              window_guards_need_repair_existing: false,
            }
          : payload.window_guards_installed_anyway == false
          ? {
              window_guards_installed_anyway: false,
              window_guards_need_repair_existing: true,
            }
          : {}),

        ...(payload.stove_knob_covers == true
          ? {
              stove_knob_covers: true,
              permanent_stove_safety_knobs: false,
            }
          : payload.stove_knob_covers == false
          ? {
              stove_knob_covers: false,
              permanent_stove_safety_knobs: true,
            }
          : {}),
        dates: moment(payload.dates).format("YYYY-MM-DD"),
        phone_number: deFormatPhoneNumber(payload.phone_number),
        survey_id: survey,
      },
      survey_id: survey,
      token,
    });
  };

  return (
    <div className="survey-form-container">
      {isAlreadySubmitted ? (
        <div className="loader-center">
          <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
            <p>{message}</p>
            <p>
              {message == "This survey response has been submitted."
                ? "Esta respuesta de la encuesta ha sido enviada."
                : "Esta respuesta a la encuesta ya ha sido enviada."}
            </p>
          </Alert>
        </div>
      ) : (
        <form onSubmit={handleSubmit(submit)}>
          <Stack>
            <Grid container spacing={4}>
              {!isFromQr && (
                <Grid item xs={12} md={4} className="d-flex align-items-center">
                  <p className="label-field">Survey ID:</p>
                  {isShow ? (
                    <p className="survey-id-text">{initialData?.survey_id}</p>
                  ) : (
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      error={!!surveyError}
                      helperText={surveyError}
                      defaultValue={initialData?.survey_id}
                      onChange={handleChange}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#16304f", // Change this to your desired focus color
                          },
                        },
                        "& .MuiInputLabel-root": {
                          "&.Mui-focused": {
                            color: "#16304f", // Change this to your desired label focus color
                            fontFamily: "segoe_uisemibold",
                            fontSize: "13px",
                          },
                        },
                      }}
                    />
                  )}
                </Grid>
              )}
              <Grid
                item
                xs={12}
                md={isFromQr || isShow ? 8 : 5}
                className="d-flex align-items-center"
              >
                <p className="label-field">Address:</p>
                <p className="survey-id-text">
                  {watch("building_address") || ""}
                </p>
              </Grid>
              {!isFromQr && !isShow && (
                <Grid
                  item
                  xs={12}
                  md={3}
                  className="d-flex justify-content-end align-items-center"
                >
                  <InputFileUpload
                    watch={watch}
                    register={register}
                    getValues={getValues}
                    errors={errors}
                    isRequiredUpload={isRequiredUpload}
                  />
                </Grid>
              )}
            </Grid>
            {disableSubmitForm() && (
              <p style={{ color: "red" }} className="mt-4">
                You cannot edit this survey because the report has already been
                uploaded.
              </p>
            )}
            <Grid className="d-flex mt-4">
              {!isFromQr && (
                <div className="verticle-div">
                  <img src={Vertical} />
                </div>
              )}
              <div className="horizontal-div">
                {fields.map((el, idx) => (
                  <div key={`checkbox-survey-${idx}`} className="mb-5">
                    <div className="check-parent d-flex">
                      <div className="mr-2">
                        <label class="custom-checkbox-large">
                          <input
                            type="checkbox"
                            // id={`assign-checkbox-${params.row.job_id}`}
                            // key={`assign-checkbox-${params.row.job_id}`}
                            // onClick={onChange}
                            // defaultChecked={
                            //   inspector ? inspector == jobInspector : false
                            // }
                            // checked={inspector ? inspector == jobInspector : false}
                            disabled={isShow}
                            {...register(el.name)}
                          />
                          <span class="checkmark-large"></span>
                        </label>
                      </div>
                      <div>
                        <p className="eng-language">{el.engText}</p>
                        <p className="span-language">{el.spnText}</p>
                      </div>
                    </div>
                    {el.children.map((child, childIdx) => (
                      <div
                        className="check-child d-flex mt-3"
                        key={`checkbox-survey-child-${childIdx}`}
                      >
                        <div className="mr-2">
                          <label class="custom-checkbox-large">
                            <input
                              type="radio"
                              // id={`assign-checkbox-${params.row.job_id}`}
                              // key={`assign-checkbox-${params.row.job_id}`}
                              // onClick={onChange}
                              value={child.value == watch(child.name)}
                              // defaultChecked={inspector ? inspector == jobInspector : false}
                              checked={
                                child.value == watch(child.name) ? true : false
                              }
                              onChange={() => setValue(child.name, child.value)}
                              disabled={isShow}
                              // {...register(child.name)}
                              name={child.name}
                            />
                            <span class="checkmark-large-radio"></span>
                          </label>
                        </div>
                        <div>
                          <p className="eng-language-600">{child.engText}</p>
                          <p className="span-language">{child.spnText}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
                {/* Blue Text */}

                <div key={`checkbox-survey-000`} className="mb-5">
                  <div className="check-parent d-flex">
                    <div className="mr-2">
                      <label class="custom-checkbox-large">
                        <input
                          type="checkbox"
                          // id={`assign-checkbox-${params.row.job_id}`}
                          // key={`assign-checkbox-${params.row.job_id}`}
                          // onClick={onChange}
                          // defaultChecked={inspector ? inspector == jobInspector : false}
                          checked={underSix ? true : false}
                          disabled={isShow}
                          onChange={(e) =>
                            setValue(
                              "child_under_6",
                              e.target.checked == true ? true : null
                            )
                          }
                        />
                        <span class="checkmark-large"></span>
                      </label>
                    </div>
                    <div className="d-flex">
                      <p className="eng-language-blue">I HAVE</p>
                      <div className="mr-2 ml-2">
                        <label class="custom-checkbox-large">
                          <input
                            type="checkbox"
                            // id={`assign-checkbox-${params.row.job_id}`}
                            // key={`assign-checkbox-${params.row.job_id}`}
                            // onClick={onChange}
                            // defaultChecked={inspector ? inspector == jobInspector : false}
                            // checked={inspector ? inspector == jobInspector : false}
                            disabled={isShow}
                            checked={underSix == false ? true : false}
                            onChange={(e) =>
                              setValue(
                                "child_under_6",
                                e.target.checked == true ? false : null
                              )
                            }
                          />
                          <span class="checkmark-large"></span>
                        </label>
                      </div>
                      <p className="eng-language-blue">
                        I DO NOT HAVE a child under age six residing in my
                        apartment, and want
                      </p>
                    </div>
                  </div>
                  {/* Childern */}
                  {/* <div
                    className="check-child d-flex mt-3"
                    key={`checkbox-survey-child-blue-1`}
                  >
                    <div className="mr-2">
                      <label class="custom-checkbox-large">
                        <input
                          type="checkbox"
                          // id={`assign-checkbox-${params.row.job_id}`}
                          // key={`assign-checkbox-${params.row.job_id}`}
                          // onClick={onChange}
                          // defaultChecked={inspector ? inspector == jobInspector : false}
                          // checked={inspector ? inspector == jobInspector : false}
                          disabled={isShow}
                          {...register("stove_knob_covers")}
                        />
                        <span class="checkmark-large"></span>
                      </label>
                    </div>
                    <div>
                      <p className="eng-language-blue-400">
                        Stove knob covers or replacement stove knob covers for
                        my stove; OR
                      </p>
                    </div>
                  </div>
                  <div
                    className="check-child d-flex mt-3"
                    key={`checkbox-survey-child-blue-2`}
                  >
                    <div className="mr-2">
                      <label class="custom-checkbox-large">
                        <input
                          type="checkbox"
                          // id={`assign-checkbox-${params.row.job_id}`}
                          // key={`assign-checkbox-${params.row.job_id}`}
                          // onClick={onChange}
                          // defaultChecked={inspector ? inspector == jobInspector : false}
                          // checked={inspector ? inspector == jobInspector : false}
                          disabled={isShow}
                          {...register("permanent_stove_safety_knobs")}
                        />
                        <span class="checkmark-large"></span>
                      </label>
                    </div>
                    <div>
                      <p className="eng-language-blue-400">
                        Permanent stove safety knobs with intergrated locking
                        mechanisms for my stove.
                      </p>
                    </div>
                  </div> */}

                  <div
                    style={{
                      paddingLeft: "25px",
                      paddingTop: "12px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        border: "2px solid #1883D2",
                        borderRight: 0,
                        height: "40px",
                        position: "relative",
                        top: "4px",
                        width: "30px",
                      }}
                    >
                      <p
                        style={{
                          position: "relative",
                          background: "#fff",
                          top: "12px",
                          margin: 0,
                          color: "#1883D2",
                          fontWeight: 600,
                          fontSize: "13px",
                          left: "-25px",
                        }}
                      >
                        Choose:
                      </p>
                    </div>

                    <div style={{ paddingLeft: "12px" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "8px",
                        }}
                      >
                        <div style={{ marginRight: "8px" }}>
                          <p
                            style={{
                              color: "#1883D2",
                              margin: 0,
                              fontWeight: 600,
                            }}
                          >
                            A
                          </p>
                        </div>

                        <label class="custom-checkbox-large">
                          <input
                            type="radio"
                            // id={`assign-checkbox-${params.row.job_id}`}
                            // key={`assign-checkbox-${params.row.job_id}`}
                            // onClick={onChange}
                            value={true == watch("stove_knob_covers")}
                            // defaultChecked={inspector ? inspector == jobInspector : false}
                            checked={true == watch("stove_knob_covers")}
                            onChange={() => setValue("stove_knob_covers", true)}
                            disabled={isShow}
                            // {...register(child.name)}
                            name={"stove_knob_covers"}
                          />
                          <span class="checkmark-large-radio"></span>
                        </label>
                        <div style={{ marginLeft: "8px" }}>
                          <p
                            style={{
                              marginTop: 0,
                              fontWeight: 400,
                              marginBottom: 0,
                              color: "#1883D2",
                            }}
                          >
                            Stove knob covers or replacement stove knob covers
                            for my stove; OR
                          </p>
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "8px",
                        }}
                      >
                        <div style={{ marginRight: "8px" }}>
                          <p
                            style={{
                              color: "#1883D2",
                              margin: 0,
                              fontWeight: 600,
                            }}
                          >
                            B
                          </p>
                        </div>

                        <label class="custom-checkbox-large">
                          <input
                            type="radio"
                            // id={`assign-checkbox-${params.row.job_id}`}
                            // key={`assign-checkbox-${params.row.job_id}`}
                            // onClick={onChange}
                            value={false == watch("stove_knob_covers")}
                            // defaultChecked={inspector ? inspector == jobInspector : false}
                            checked={false == watch("stove_knob_covers")}
                            onChange={() =>
                              setValue("stove_knob_covers", false)
                            }
                            disabled={isShow}
                            // {...register(child.name)}
                            name={"stove_knob_covers"}
                          />
                          <span class="checkmark-large-radio"></span>
                        </label>
                        <div style={{ marginLeft: "8px" }}>
                          <p
                            style={{
                              marginTop: 0,
                              fontWeight: 400,
                              marginBottom: 0,
                              color: "#1883D2",
                            }}
                          >
                            Permanent stove safety knobs with integrated locking
                            mechanisms for my stove.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Blue Text End */}
              </div>
            </Grid>
            <Grid container spacing={4}>
              {surveyDetailsFields
                .filter((el) => (isFromQr ? el.name !== "response_type" : true))
                .map((el, idx) => (
                  <Grid
                    item
                    xs={12}
                    md={isFromQr ? 6 : el.size}
                    className="d-flex align-items-center"
                    key={`survey-details-fields-${idx}`}
                  >
                    <p className="label-field">{el.label}</p>
                    {el.type == "date" ? (
                      <CustomDatePicker
                        value={watch(el.name)}
                        defaultValue={initialData[el.name] || ""}
                        errors={errors}
                        disabled={true}
                        field={el}
                        setValue={setValue}
                        {...register(el.name, {
                          required: `${el.label} is required`,
                        })}
                        // isShring={true}
                        // placeholder={el.placeholder}
                      />
                    ) : el.type == "number" ? (
                      <TextField
                        label={el.label}
                        fullWidth
                        {...register(
                          el.name,
                          isFromQr && {
                            required: `${el.label} is required`,
                          }
                        )}
                        onChange={(e) => {
                          setValue(el.name, formatPhoneNumber(e.target.value));
                        }}
                        InputLabelProps={{ shrink: true }}
                        error={!!errors[el.name]}
                        helperText={errors[el.name]?.message}
                        disabled={el.disabled || isShow || false}
                        placeholder={el.placeholder}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "#16304f", // Change this to your desired focus color
                            },
                          },
                          "& .MuiInputLabel-root": {
                            "&.Mui-focused": {
                              color: "#16304f", // Change this to your desired label focus color
                              fontFamily: "segoe_uisemibold",
                              fontSize: "13px",
                            },
                          },
                        }}
                      />
                    ) : el.type == "select" ? (
                      <div className="survey-form-dropdown">
                        <CustomSelectDropdown
                          defaultValue={watch(el.name) || ""}
                          options={el.options}
                          label={el.placeholder}
                          selectedValue={watch(el.name) || ""}
                          onSelectChange={(value) => {
                            // setSelectedValue(value);
                            setValue(el.name, value);
                          }}
                          {...register(el.name)}
                        />
                      </div>
                    ) : (
                      <TextField
                        fullWidth
                        {...register(
                          el.name,
                          isFromQr && {
                            required: `${el.label} is required`,
                          }
                        )}
                        InputLabelProps={{ shrink: true }}
                        error={!!errors[el.name]}
                        helperText={errors[el.name]?.message}
                        disabled={isShow}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "#16304f", // Change this to your desired focus color
                            },
                          },
                          "& .MuiInputLabel-root": {
                            "&.Mui-focused": {
                              color: "#16304f", // Change this to your desired label focus color
                              fontFamily: "segoe_uisemibold",
                              fontSize: "13px",
                            },
                          },
                        }}
                      />
                    )}
                  </Grid>
                ))}
            </Grid>
            {isFromQr && (
              <SignatureForm
                setValue={setValue}
                setError={setError}
                clearErrors={clearErrors}
                register={register}
                errors={errors}
              />
            )}

            {!isShow ? (
              <Stack
                sx={{
                  alignSelf: "end",
                }}
                direction={"row"}
                spacing={2}
                className="popup-footer-border"
              >
                <Button
                  variant="outlined"
                  className="secondary-btn-class"
                  onClick={onClose}
                  // disabled={loading}
                >
                  Cancel
                </Button>
                {/* //capitalize h-42 */}
                <Button
                  type="submit"
                  variant="contained"
                  className={disableSubmitForm() ? "" : "primary-btn-class"}
                  disabled={disableSubmitForm()}
                  sx={{ boxShadow: "none" }}
                >
                  Submit
                </Button>
              </Stack>
            ) : (
              <div className="mt-4"></div>
            )}
          </Stack>
        </form>
      )}
    </div>
  );
}

export default EnterSurveyForm;
