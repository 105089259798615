import React from "react";
import LeftFilters from "./LeftFilter";
import GlobalSearch from "../../../components/Search";
import DownloadAuditReport from "../../Client/detail/components/Units/DownloadAuditReport";
import { downloadAuditReportFire } from "../../../sevices/fireNoticeApi";


function Filters({ building, client }) {
  return (
    <div className="tickets-filter d-flex align-items-center justify-content-space-between">
      <LeftFilters />
      <div className="d-flex">
        <GlobalSearch />
        <div className="mr-4" />
        <DownloadAuditReport
          building={building}
          client={client}
          downloadAuditReportPdf={downloadAuditReportFire}
          reportId={"firePdfId"}
        />
      </div>
    </div>
  );
}

export default Filters;
