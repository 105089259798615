import React, { useState } from "react";
import {
  Drawer,
  Box,
  DialogTitle,
  IconButton,
  DialogContent,
  Stack,
  Divider,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { NavLink } from "react-router-dom";
import moment from "moment";

function MobileMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [menu, setMenu] = useState({
    right: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setMenu({ ...menu, [anchor]: open });
  };
  return (
    <div>
      <div onClick={() => setMenu({ ...menu, right: true })}>
        <MenuIcon />
      </div>
      {menu && (
        <>
          {["right"].map((anchor) => (
            <React.Fragment key={anchor}>
              <Drawer
                anchor={anchor}
                open={menu[anchor]}
                onClose={toggleDrawer(anchor, false)}
              >
                <Box
                  sx={{
                    width:
                      anchor === "top" || anchor === "bottom" ? "auto" : 250,
                  }}
                  role="presentation"
                >
                  <DialogTitle
                    sx={{
                      m: 0,
                      p: 2,
                      fontWeight: "700",
                      bgcolor: "rgba(242, 246, 254, 1)",
                    }}
                    id="customized-dialog-title"
                    className="blue-text-color attachment-header-text"
                  >
                    Menu
                  </DialogTitle>
                  <IconButton
                    aria-label="close"
                    onClick={toggleDrawer(anchor, false)}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                  <DialogContent>
                    <ul className={`nav__list__web-r d-flex`}>
                      <Stack direction="column" spacing={2}>
                        <li>
                          <NavLink
                            to="/calendar"
                            onClick={() => toggleDrawer(anchor, false)}
                          >
                            Calendar
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to={`/ticket?date=${JSON.stringify({
                              key: "selection",
                              startDate: moment(new Date()).format(
                                "YYYY-MM-DD"
                              ),
                              endDate: moment(new Date()).format("YYYY-MM-DD"),
                            })}`}
                            onClick={() => toggleDrawer(anchor, false)}
                          >
                            Tickets
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/client"
                            onClick={() => toggleDrawer(anchor, false)}
                          >
                            All Clients
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/inspector"
                            onClick={() => toggleDrawer(anchor, false)}
                          >
                            Inspectors
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/service"
                            onClick={() => toggleDrawer(anchor, false)}
                          >
                            Services
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/terms"
                            onClick={() => toggleDrawer(anchor, false)}
                          >
                            Terms
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to={`/lead-compliance?year=${new Date().getFullYear()}`}
                            onClick={() => toggleDrawer(anchor, false)}
                          >
                            Annual Compliance
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to={`/fire-notice?year=${new Date().getFullYear()}`}
                            onClick={() => toggleDrawer(anchor, false)}
                          >
                            Fire Notices
                          </NavLink>
                        </li>
                      </Stack>
                    </ul>
                  </DialogContent>
                </Box>
              </Drawer>
            </React.Fragment>
          ))}
        </>
      )}
    </div>
  );
}

export default MobileMenu;
