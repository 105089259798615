export const fields = [
  {
    name: "child_under_6_required",
    engText:
      "A child 6 years or younger lives in my apartment or routinely spends 10 or more hours each week in my apartment",
    spnText:
      "Un niño de 6 años o menos vive en mi apartamento o gasta regularmente 10 o más horas cada semana en mi apartamento",
    children: [],
  },
  {
    name: "child_under_10",
    engText: " A child 10 years or younger lives in my apartment and:",
    spnText: "Un niño de 10 años o menos vive en mi apartamento y:",
    children: [
      {
        name: "window_guards_installed",
        value: true,
        engText: " Window guards are installed in all windows as required.",
        spnText:
          "Las rejas de seguridad obligatorias han sido instaladas en todas las ventanas",
      },
      {
        name: "window_guards_installed",
        value: "repair",
        engText: "Window guards are installed but need repair.",
        spnText:
          "Las protecciones de las ventanas están instaladas pero necesitan reparación.",
      },
      {
        name: "window_guards_installed",
        value: false,
        engText: "Window guards are NOT installed in all windows as required.",
        spnText: "Las rejas de seguridad obligatorias NO han sido instaladas",
      },
    ],
  },
  {
    name: "child_under_10_not_living",
    engText: " No child 10 years or younger lives in my apartment.",
    spnText: "Ningún niño de 10 años o menos vive en mi apartamento",
    children: [
      {
        name: "window_guards_installed_anyway",
        value: true,
        engText: "I want window guards installed anyway.",
        spnText:
          "Quiero tener las rejas de seguridad instaladas de todos modos",
      },
      {
        name: "window_guards_installed_anyway",
        value: false,
        engText: "I have window guards but they need repair.",
        spnText: "Tengo las rejas de seguridad, pero necesitan reparación",
      },
    ],
  },
];

export const surveyDetailsFields = [
  { name: "first_name", label: "First Name", type: "text", size: 6 },
  { name: "last_name", label: "Last Name", type: "text", size: 6 },
  { name: "phone_number", label: "Telephone #", type: "number", size: 4 },
  { name: "dates", label: "Date (Fecha)", type: "date", size: 4 },
  {
    name: "response_type",
    label: "Response Type",
    type: "select",
    size: 4,
    placeholder: "Select Response Type",
    options: [
      {
        label: "DROPBOX",
        value: "DROPBOX",
      },
      {
        label: "DOOR KNOCK",
        value: "DOOR KNOCK",
      },
      {
        label: "RESPONSE TO OWNER",
        value: "RESPONSE TO OWNER",
      },
      {
        label: "QR RESPONSE",
        value: "QR RESPONSE",
      },
    ],
  },
];
