import React, { useState } from "react";
import { addLeadCompliance } from "../../../../../sevices/leadComplianceApi";
import useCustomMutate from "../../../../../hooks/useMutate";

function MailerProgram({ data, apiCallback, programKey }) {
  const [loading, setLoading] = useState(false);
  const { mutate } = useCustomMutate(apiCallback, ["building", "units"], () => {
    setLoading(false);
  });
  const onChange = (event) => {
    setLoading(true);
    const reqBody = {
      building_id: data.building_id,
      year: new Date().getFullYear(),
    };
    reqBody[programKey] = event.target.checked;
    mutate(reqBody);
  };
  return (
    <div>
      <label class="custom-checkbox-large">
        <input
          type="checkbox"
          id={`assign-checkbox-${data?.building_id}`}
          onClick={onChange}
          defaultChecked={data?.active}
          // checked={data.mailer_program ? true : false}
          disabled={loading}
        />
        <span
          class="checkmark-large"
          //   style={{ backgroundColor: `${!inspector ? "#ebebeb" : ""}` }}
        ></span>
      </label>
    </div>
  );
}

export default MailerProgram;
