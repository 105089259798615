import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { CircularProgress } from "@mui/material";

import upload from "../../../../styles/svg/upload.svg";
import eye from "../../../../styles/svg/eye-view.svg";
import { complianceReportDelete, complianceReportUpload } from "../../../../sevices/leadComplianceApi";
import LongFormDialog from "../../../../components/Dialog/LongFormDialog";
import DownloadSurveyCompliance from "./DownloadSurveyCompliance";
import ViewFile from "../../../../components/ViewFile";
import toast from "react-hot-toast";
import { useCustomSearchParams } from "../../../../hooks/useCustomSearchParams";
import ReportActions from "./ReportActions";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function Report({ id, attachment, refetchUnits, status, type }) {
  const [anchorEl, setAnchorEl] = useState(false);
  const [refetch, setRefetch] = useCustomSearchParams("refetch");
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setAnchorEl(false);
  };
  const handleClick = () => {
    setAnchorEl(true);
  };

  const callback = (res) => {
    toast.success(res.data.message);
    setRefetch("refetch", refetch ? refetch + 1 : 1);
    refetchUnits?.();
  };

  const uploadFile = (e) => {
    setLoading(true);
    const { files } = e.target;
    console.log(files);
    if (files.length) {
      complianceReportUpload({
        compliance_id: id,
        report: files[0],
        report_type: type,
      })
        .then((res) => {
          callback(res);
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.response?.data?.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  return (
    <>
      {anchorEl && (
        <LongFormDialog
          open={anchorEl}
          handleClose={handleClose}
          title={"Survey Response"}
          btnTitle={"Confirm"}
          IsChildren={
            <ReportActions
              url={"/compliance/report/" + attachment}
              name={attachment}
              id={id}
              handleClose={handleClose}
              callback={callback}
              type={type}
              api={complianceReportDelete}
            />
          }
        >
          <ViewFile url={"/compliance/report/" + attachment} />
        </LongFormDialog>
      )}

      {attachment ? (
        <img src={eye} className="ml-1 cursor-pointer" onClick={handleClick} />
      ) : (
        status == "Report-Needed" && (
          <label
            htmlFor={`upload-file-${type}-${id}`}
            className="ml-1 cursor-pointer"
            key={`compliance-upload-form-${type}-` + id}
          >
            {loading ? (
              <CircularProgress size={18} color="inherit" />
            ) : (
              <img src={upload} />
            )}
            <VisuallyHiddenInput
              type="file"
              onChange={uploadFile}
              id={`upload-file-${type}-${id}`}
            />
          </label>
        )
      )}
    </>
  );
}

export default Report;
