import React, { useEffect, useState } from 'react'
import { Button, CircularProgress } from "@mui/material";
import PrimaryButton from '../../../../../components/Button/Button'
import DownloadIcon from '../../../../../styles/svg/down-arrow-download.svg'
import toast from 'react-hot-toast';
import { checkStatusAuditReport, downloadAuditReport } from '../../../../../sevices/leadComplianceApi';
import useDownloader from 'react-use-downloader';
import { useCustomSearchParams } from '../../../../../hooks/useCustomSearchParams';
import { useClientBuildings } from '../../../../../reactQuery/building';

function DownloadAuditReport({
  client,
  building,
  downloadAuditReportPdf,
  reportId,
  btnText = "Audit Compliance Report",
}) {
  const [loading, setLoading] = useState(false);
  const { download } = useDownloader();
  const [year] = useCustomSearchParams("year");
  const [isGenerating, setIsGenerating] = useState(
    localStorage.getItem(reportId) ? true : false
  );
  const [pdfId, setPdfId] = useState(localStorage.getItem(reportId) || null);

  const { responseData } = useClientBuildings(
    {
      page: 1,
      pageSize: 1,
      clientId: client,
      pagination: false,
    },
    client
  );

  // Polling logic
  useEffect(() => {
    let interval;

    if (pdfId && isGenerating) {
      interval = setInterval(async () => {
        checkStatusAuditReport(pdfId)
          .then((res) => {
            if (res.data.data.task_status === "completed") {
              const buildingAddress =
                building &&
                responseData?.find((el) => el?.building_id == building).address;

              clearInterval(interval);
              download(
                `${process.env.REACT_APP_BASE_URL}/audit/${res.data.data.file_name}`,
                `audit-report-${year}${
                  building ? `-${buildingAddress}-${building}` : ""
                }.pdf`
              );
              setIsGenerating(false);
              setPdfId(null);
              localStorage.removeItem(reportId);
            } else if (res.data.data.task_status === "failed") {
              toast.error(res.data.data.message);
              clearInterval(interval);
              setIsGenerating(false);
              setPdfId(null);
              localStorage.removeItem(reportId);
            }
          })
          .catch((err) => {});
      }, 6000);
    }

    return () => clearInterval(interval);
  }, [pdfId, isGenerating]);

  const downloadReport = () => {
    setLoading(true);
    setIsGenerating(true);
    downloadAuditReportPdf({
      building_id: building,
      year: year,
      client_id: client,
    })
      .then((res) => {
        setPdfId(res.data.task_id);
        localStorage.setItem(reportId, res.data.task_id);
        toast.success(res.data.message);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
        setLoading(false);
        setIsGenerating(false);
      });
  };

  return (
    <>
      <PrimaryButton
        variant="contained"
        className={`capitalize ${isGenerating ? " " : "audit-button"} `}
        // disabled={true}
        disabled={isGenerating}
        onClick={downloadReport}
        startIcon={
          isGenerating ? (
            <CircularProgress size={20} color="inherit" />
          ) : (
            <img src={DownloadIcon} />
          )
        }
      >
        {isGenerating ? "Generating Audit Report" : btnText}
      </PrimaryButton>
    </>
  );
}

export default DownloadAuditReport