import { useQuery } from "@tanstack/react-query";
import { getFireNotiesCompliance } from "../sevices/fireNoticeApi";


export function useFireNotice(keys) {
  const { isLoading, data, error, isError, refetch, isRefetching } = useQuery({
    queryKey: ["fire-notice"],
    queryFn: () => getFireNotiesCompliance(keys),
  });

  const responseData =
    data?.data?.data?.response_data?.map((el, idx) => {
      return {
        ...el,
        id: idx + 1,
      };
    }) || [];
  const totalRecords = data?.data?.data?.total_records;

  const statusCount = data?.data?.data?.status_counts;

  return {
    isLoading,
    error,
    responseData,
    isError,
    refetch,
    totalRecords,
    statusCount,
    isRefetching,
  };
}
