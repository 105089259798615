import moment from "moment";
import Action from "./Action";
import BuildingName from "./BuildingName";
import MailerProgram from "./MailerProgram";
import Green from "../../../../../styles/svg/compliance-green.svg";

export const columns = [
  {
    field: "address",
    headerName: "Building",
    renderCell: (params) => {
      return <BuildingName params={params} />;
    },
    minWidth: 140,
    flex: 1,
    // editable: true,
    sortable: true,
  },
  {
    field: "units",
    headerName: "#Units",
    renderCell: (params) => {
      return (
        <p className="w-100 d-flex justify-content-center">
          {params?.value.length}
        </p>
      );
    },
    renderHeader: (cell) => (
      <div className="w-100 d-flex justify-content-center">
        <p>#Units</p>
      </div>
    ),
    minWidth: 140,
    flex: 1,
    // editable: true,
    headerClassName: "center-header",
  },
  {
    field: "mailer_program",
    headerName: "Mailer Program",
    renderCell: (params) => {
      return (
        <div className="w-100 d-flex justify-content-center">
          {params.row?.mailer_program ? <img src={Green} /> : ""}
          {/* <MailerProgram data={params.row} /> */}
        </div>
      );
    },
    renderHeader: (cell) => (
      <div className="w-100 d-flex justify-content-center">
        <p>Mailer Program</p>
      </div>
    ),
    minWidth: 100,
    flex: 1,
    // editable: true,
    sortable: false,
    headerClassName: "center-header",
  },
  {
    field: "fire_notice_active",
    headerName: "Fire Notice Program",
    renderCell: (params) => {
      return (
        <div className="w-100 d-flex justify-content-center">
          {params.row?.fire_notice_active ? <img src={Green} /> : ""}
          {/* <MailerProgram data={params.row} /> */}
        </div>
      );
    },
    renderHeader: (cell) => (
      <div className="w-100 d-flex justify-content-center">
        <p>Fire Notice Program</p>
      </div>
    ),
    minWidth: 100,
    flex: 1,
    // editable: true,
    sortable: false,
    headerClassName: "center-header",
  },
  {
    field: "action",
    headerName: "Action",
    renderCell: (params) => {
      return (
        <div className=" w-100 d-flex justify-content-center">
          <Action params={params} />
        </div>
      );
    },
    renderHeader: (cell) => (
      <div className="w-100 d-flex justify-content-center">
        <p>Action</p>
      </div>
    ),
    minWidth: 100,
    flex: 1,
    // editable: true,
    sortable: false,
    headerClassName: "center-header",
  },
];
