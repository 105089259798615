import ViewSurvey from "../../../Client/detail/components/Units/ViewSurvey";
import RedirectToClient from "../../../Tickets/components/RedirectToClient";
import InspectionScheduled from "./InspectionScheduled";
import WindowGuards from "./WindowGuards";
import KnobCovers from "./KnobCovers";
import ApartmentNumberColumns from "./ApartmentNumberColumns";

export const columns = [
  {
    field: "client",
    headerName: "Client",
    renderCell: (params) => {
      return <RedirectToClient params={params} />;
    },
    minWidth: 100,
    flex: 1,
    // editable: true,
    // sortable: false,
  },
  {
    field: "building_address",
    headerName: "Building",
    renderCell: (params) => {
      return <p className="">{params.value}</p>;
    },
    minWidth: 110,
    flex: 1.5,
    // editable: true,
    // sortable: false,
  },

  {
    field: "unit_number",
    headerName: "Unit",
    renderCell: (params) => {
      return <ApartmentNumberColumns params={params} />;
    },
    minWidth: 100,
    flex: 0.7,
    // editable: true,
    // sortable: false,
  },
  {
    field: "mailer_count",
    headerName: "Mailers Send",
    renderCell: (params) => {
      return <p className="">{params.value}</p>;
    },
    minWidth: 100,
    flex: 0.7,
    // editable: true,
    sortable: false,
  },
  {
    field: "response_type",
    headerName: "Response Type",
    renderCell: (params) => {
      return <p className="">{params.row.compliance?.response_type || ''}</p>;
    },
    minWidth: 100,
    flex: 0.7,
    // editable: true,
    sortable: false,
  },
  {
    field: "window_guards_installed",
    headerName: "Window Guards",
    renderCell: (params) => {
      return <WindowGuards params={params} />;
    },
    minWidth: 120,
    flex: 0.8,
    // editable: true,
    sortable: false,
  },
  {
    field: "knob_covers",
    headerName: "Knob Covers",
    renderCell: (params) => {
      return <KnobCovers params={params} />;
    },
    minWidth: 120,
    flex: 0.8,
    // editable: true,
    sortable: false,
  },
  {
    field: "inspection",
    headerName: "Visual Inspection",
    renderCell: (params) => {
      return (
        <>
          <InspectionScheduled
            params={params}
            key={`schedule_inspection_${params.row?.compliance?.compliance_id}`}
          />
        </>
      );
    },
    renderHeader: (cell) => (
      <div className="w-100 d-flex justify-content-center">
        <p className="size-14 text-color weight-500">Visual Inspection</p>
      </div>
    ),
    minWidth: 120,
    flex: 0.8,
    // editable: true,
    sortable: false,
    headerClassName: "center-header",
  },
  {
    field: "survey",
    headerName: "Survey",
    renderCell: (params) => {
      return <ViewSurvey params={params} />;
    },
    renderHeader: (cell) => (
      <div className="w-100 d-flex justify-content-center">
        <p className="size-14 text-color weight-500">Survey</p>
      </div>
    ),
    minWidth: 110,
    flex: 0.8,
    // editable: true,
    sortable: false,
    headerClassName: "center-header ",
  },
];
