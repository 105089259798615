import React from 'react'
import ComplianceYear from '../../../../LeadCompliance/components/Header/ComplianceYear';
import DownloadAuditReport from './DownloadAuditReport';
import { downloadAuditReport } from '../../../../../sevices/leadComplianceApi';
import { downloadAuditReportFire } from '../../../../../sevices/fireNoticeApi';

function LeadComplianceHeading({ building, client, isGrey, isGreyFire }) {
  console.log("isGrey", isGrey || isGreyFire);
  return (
    <div
      className={` d-flex  align-items-center ${
        isGrey && isGreyFire ? "" : "lead-compliance-unit-table"
      }`}
    >
      <ComplianceYear name={"Annual Compliance"} />
      <div className={`${isGrey ? "lead-compliance-unit-table-gred" : ""} `}>
        <DownloadAuditReport
          building={building}
          client={client}
          downloadAuditReportPdf={downloadAuditReport}
          reportId={"mailerPdfId"}
        />
      </div>

      <div className="mr-2"></div>
      <div
        className={`${isGreyFire ? "lead-compliance-unit-table-gred" : ""}  `}
      >
        <DownloadAuditReport
          building={building}
          client={client}
          downloadAuditReportPdf={downloadAuditReportFire}
          reportId={"firePdfId"}
          btnText="Audit Fire Compliance Report"
        />
      </div>
    </div>
  );
}

export default LeadComplianceHeading