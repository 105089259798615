import moment from "moment";
import Api from "./axios";
import { convertInUTC } from "../utils/helpers";

export const downloadExcelReport = async (data) => {
   const prepareDateRangeQuery = data.date
     ? `&start_date=${data.date.startDate}&end_date=${data.date.endDate}`
     : "";
  return await Api.get(
    `/v1/job?client_id=${data?.clientId}&pagination=false${prepareDateRangeQuery}`
  );
}

export const getJobs = async (data) => {
  // const dateRange = convertInUTC(data.date.startDate, data.date.endDate);
  const prepareDateRangeQuery = data.date
    ? `&start_date=${data.date.startDate}&end_date=${data.date.endDate}`
    : "";

  return await Api.get(
    `/v1/job/?page=${data.page || ""}&page_size=${data.pageSize || ""}${
      data.status ? `&${data.status}` : ""
    }&search=${data.search || ""}&pagination=${
      data.pagination
    }${prepareDateRangeQuery}${
      data.inspector ? `&inspector_id=${data.inspector}` : ""
    }${data?.assign || ""}${data?.download || ""}${data?.client || ""}${
      data?.unitId || ""
    }${data?.buildingId || ""}`
  );
};

export const createJobs = async (data) => {
  //start_date=${data.start_date}&end_date=${data.end_date}&status=&search=&report_available=&
  return await Api.post(`/v1/job`, data);
};

export const updateJobs = async ({ id, data }) => {
  //start_date=${data.start_date}&end_date=${data.end_date}&status=&search=&report_available=&
  return await Api.put(`/v1/job/${id}`, data);
};

export const deleteJobs = async (id) => {
  return await Api.delete(`/v1/job/${id}`);
};

///v1/job/report-status

export const getReportOptions = async () => {
  return await Api.get(`/v1/job/report-status`);
};

export const uploadReport = async ({ data, id }) => {
  return await Api.post(`/v1/job/${id}/report-upload`, data);
};

export const downloadReport = async (id) => {
  return await Api.get(`/v1/job/${id}/report-download`);
};
export const isAvailableDate = async (date) => {
  return await Api.get(`v1/date-override/check-date-limit?date=${date}`);
};

export const downloadPdfAffadevit = async (data) => {
  return await Api.post(`/v1/job/generate-pdf`, data);
};

export const deletePdfAffadevit = async (fileName) => {
  return await Api.delete(`/v1/job/delete-pdf/${fileName}`);
};

export const getAffadevitData = async (data) => {
  return await Api.get(
    `/v1/building/${data.building}/inspector/${data.inspector}`
  );
};

export const checkDuplicateJobId = async (ticket_id) => {
  return await Api.get(`/v1/job/${ticket_id}/exists`);
};


///v1/job{ticket_id}/exists