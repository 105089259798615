import React, { useEffect } from 'react'
import Header from './Header'
import { useCustomSearchParams } from '../../hooks/useCustomSearchParams';
import Filters from './filters.js';
import Table from './table/index.js';

function FireNotice() {
  const [year, setYear] = useCustomSearchParams("year");
  const currentYear = new Date().getFullYear();
  const [building_id] = useCustomSearchParams("building");
  const [client] = useCustomSearchParams("client");

   useEffect(() => {
     setYear("year", year || currentYear);
   }, []);
  return (
    <div>
      <Header />
      <div className="mt-4 ticket-page">
        <Filters building={building_id} client={client} />
        <Table />
      </div>
    </div>
  );
}

export default FireNotice